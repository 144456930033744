import { FC, ReactNode } from 'react'

import { AnimatedBlobBackground } from '@sergeimeza/uikit-next'

const AnyAnimatedBlobBackground = AnimatedBlobBackground as FC<any>

const OnboardingLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <div>
    <main className='grid min-h-screen items-center justify-center'>
      <AnyAnimatedBlobBackground
        type={AnimatedBlobBackground.type.small}
        colors={['bg-yellow-300', 'bg-pink-300']}
      >
        <div className='hidden min-h-[24rem] min-w-[40rem] sm:block' />
      </AnyAnimatedBlobBackground>
      <div className='absolute grid h-full w-full items-center justify-center bg-transparent sm:mt-4'>
        <div className='mx-auto grid min-w-[26rem] max-w-[30rem] items-center justify-center rounded-lg bg-white shadow-sm'>
          {children}
        </div>
      </div>
    </main>
  </div>
)

export { OnboardingLayout }

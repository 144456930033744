'use client'
import React, { ComponentType, useEffect } from 'react'

// import { useConfig } from './use-config'
import { useAuthContext } from '@/context/AuthContext'
import { toast } from '@/utils/toast'
import { useRouter } from 'next/router'
// import { useUser, UserProfile } from './use-user'

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => <></>

/**
 * @ignore
 */
const defaultOnError = (): JSX.Element => <></>

/**
 * Options to customize the withPageAuthRequired higher order component.
 *
 * @category Client
 */
export interface WithPageAuthRequiredOptions {
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   returnTo: '/profile'
   * });
   * ```
   *
   * Add a path to return the user to after login.
   */
  returnTo?: string
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * });
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onError: error => <div>Error: {error.message}</div>
   * });
   * ```
   *
   * Render a fallback in case of error fetching the user from the profile API route.
   */
  onError?: (error: Error) => JSX.Element
}

export interface UserProps {
  // user: UserProfile
}

/**
 * ```js
 * const MyProtectedPage = withPageAuthRequired(MyPage);
 * ```
 *
 * When you wrap your pages in this higher order component and an anonymous user visits your page,
 * they will be redirected to the login page and then returned to the page they were redirected from (after login).
 *
 * @category Client
 */
export type WithPageAuthRequired = <P extends {}>(
  Component: ComponentType<P & UserProps>,
  options?: WithPageAuthRequiredOptions,
) => React.FC<P>

/**
 * @ignore
 */

const loginUrl = '/auth/sign-in'

const withPageAuthRequired: WithPageAuthRequired = (
  Component,
  options = {},
) => {
  return function WithPageAuthRequired(props): JSX.Element {
    const {
      returnTo,
      onRedirecting = defaultOnRedirecting,
      onError = defaultOnError,
    } = options
    // const { loginUrl } = useConfig()
    const router = useRouter()

    const { appUser, state } = useAuthContext()
    const isLoading = state === 'LOADING'
    useEffect(() => {
      if (appUser || isLoading) return

      let returnToPath: string

      if (!returnTo) {
        const currentLocation = window.location.toString()
        returnToPath =
          currentLocation.replace(new URL(currentLocation).origin, '') || '/'
      } else {
        returnToPath = returnTo
      }

      router
        .replace(`${loginUrl}?returnTo=${encodeURIComponent(returnToPath)}`)
        .then(() => {
          toast({
            type: 'error',
            message: 'セッションが切れました。再度ログインしてください。',
          })
        })
      // window.location.assign(
      //   `${loginUrl}?returnTo=${encodeURIComponent(returnToPath)}`,
      // )
    }, [appUser, isLoading])
    // if (error) return onError(error)
    if (appUser) return <Component {...(props as any)} />

    return onRedirecting()
  }
}

export default withPageAuthRequired

import { notify } from '../components/common/toast'

type NotifyTypes = 'success' | 'error' | 'info' | 'warning'

interface ToastParams {
  type: NotifyTypes
  message: string
  title?: string
  className?: string
}

export function toast({ type, title, message, className }: ToastParams) {
  notify({ type, data: { title, message }, className })
}

import { toast } from 'react-toastify'

type NotifyTypes = 'success' | 'error' | 'info' | 'warning'

interface NotifyParams {
  type: NotifyTypes
  data: {
    message: string
    title?: string
  }
  className?: string
}

export function notify({ type, data: { message } }: NotifyParams) {
  switch (type) {
    case 'success':
      toast.success(message)
      break
    case 'error':
      toast.error(message)
      break
    case 'warning':
      toast.warning(message)
      break
    default:
      toast(message)
  }
}

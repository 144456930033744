import { FC } from 'react'

import { classNames } from '@sergeimeza/uikit-next'

import { OnboardingLayout } from '../../layouts/OnboardingLayout'

import LemonLogo from '../../../assets/img/brand/logo-lemonsquare-horizontal.svg'
import LemonImage from '../Images/LemonImage'

export interface ILoading {
  full?: boolean
  styleClass?: string
  text?: string
}

const Loading: FC<ILoading> = ({ full, styleClass, text: loadingText }) => {
  const loadingComponent = (
    <div
      className={classNames('flex flex-col items-center', {
        'my-8': !full,
      })}
    >
      {full && (
        <>
          <h1 className='hidden'>Lemon Square</h1>
          <div className='mb-8'>
            <LemonImage
              src={LemonLogo}
              alt='Lemon Square Logo'
              width={300}
              height={150}
            />
          </div>
        </>
      )}
      {loadingText}
      <svg
        className='-ml-1 mr-3 mt-3 h-8 w-8 animate-spin text-yellow-500'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  )

  return (
    <div
      className={classNames(
        `flex h-60 w-full flex-col justify-center ${styleClass}`,
      )}
    >
      {full ? (
        <OnboardingLayout>{loadingComponent}</OnboardingLayout>
      ) : (
        loadingComponent
      )}
    </div>
  )
}

Loading.defaultProps = {
  styleClass: '',
  full: false,
  text: 'Loading...',
}

export { Loading }


Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
} = require('./runtime/index-browser')


const Prisma = {}

exports.Prisma = Prisma

/**
 * Prisma Client JS version: 4.16.2
 * Query Engine version: 4bc8b6e1b66cb932731fb1bdbbc550d1e010de81
 */
Prisma.prismaVersion = {
  client: "4.16.2",
  engine: "4bc8b6e1b66cb932731fb1bdbbc550d1e010de81"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.ElasticSearchMigrationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  key: 'key',
  success: 'success',
  error: 'error'
};

exports.Prisma.NatsMigrationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  key: 'key',
  success: 'success',
  error: 'error'
};

exports.Prisma.DailyUgcEventStatsScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  eventName: 'eventName',
  count: 'count',
  eventDate: 'eventDate'
};

exports.Prisma.DailyFirstLastClickConversionScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  conversionDate: 'conversionDate',
  firstclickConversion: 'firstclickConversion',
  lastclickConversion: 'lastclickConversion',
  ugcPageConversion: 'ugcPageConversion',
  wholeSiteConversion: 'wholeSiteConversion'
};

exports.Prisma.DailyFirstLastClickConversionPostWiseScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  conversionDate: 'conversionDate',
  firstclickConversion: 'firstclickConversion',
  lastclickConversion: 'lastclickConversion',
  mediaId: 'mediaId'
};

exports.Prisma.DailyUgcClickPostWiseScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  clicks: 'clicks',
  clicksDate: 'clicksDate',
  mediaId: 'mediaId'
};

exports.Prisma.DailyCartinScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  cartinDate: 'cartinDate',
  ugcCartins: 'ugcCartins',
  ugcPageCartins: 'ugcPageCartins',
  wholeSiteCartins: 'wholeSiteCartins'
};

exports.Prisma.DailyCartinPostWiseScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  cartinDate: 'cartinDate',
  cartins: 'cartins',
  mediaId: 'mediaId'
};

exports.Prisma.DailyAverragePanelOpenDurationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  avgOpenDuration: 'avgOpenDuration',
  avgOpenDurationCount: 'avgOpenDurationCount',
  avgOpenDurationDate: 'avgOpenDurationDate'
};

exports.Prisma.DailyUgcPageviewStatsScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  session_id: 'session_id',
  ugc_website_id: 'ugc_website_id',
  ugcSetId: 'ugcSetId',
  pageviewDate: 'pageviewDate',
  ugcPageAccessTimes: 'ugcPageAccessTimes',
  wholeSiteAccessTimes: 'wholeSiteAccessTimes'
};

exports.Prisma.UgcStoreScalarFieldEnum = {
  id: 'id',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  is_shopify: 'is_shopify',
  name: 'name',
  domain: 'domain',
  ugc_website_id: 'ugc_website_id',
  environment: 'environment',
  cart_url: 'cart_url',
  thanks_url: 'thanks_url',
  systemAlertsActive: 'systemAlertsActive'
};

exports.Prisma.ShopifySessionScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  shop: 'shop',
  state: 'state',
  isOnline: 'isOnline',
  scope: 'scope',
  expires: 'expires',
  accessToken: 'accessToken',
  onlineAccessInfo: 'onlineAccessInfo'
};

exports.Prisma.ShopifyPixelScalarFieldEnum = {
  id: 'id',
  settings: 'settings',
  sessionId: 'sessionId'
};

exports.Prisma.ShopifyShopScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  shop_id: 'shop_id',
  name: 'name',
  domain: 'domain',
  primary_locale: 'primary_locale',
  country: 'country',
  currency: 'currency',
  timezone: 'timezone',
  iana_timezone: 'iana_timezone'
};

exports.Prisma.ColormeShopScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  account_id: 'account_id',
  state: 'state',
  domain_plan: 'domain_plan',
  contract_plan: 'contract_plan',
  contract_start_date: 'contract_start_date',
  contract_end_date: 'contract_end_date',
  contract_term: 'contract_term',
  last_login_date: 'last_login_date',
  setup_date: 'setup_date',
  make_date: 'make_date',
  url: 'url',
  open_state: 'open_state',
  mobile_open_state: 'mobile_open_state',
  login_id: 'login_id',
  name1: 'name1',
  name2: 'name2',
  name1_kana: 'name1_kana',
  name2_kana: 'name2_kana',
  hojin: 'hojin',
  hojin_kana: 'hojin_kana',
  user_mail: 'user_mail',
  tel: 'tel',
  fax: 'fax',
  postal: 'postal',
  pref_id: 'pref_id',
  pref_name: 'pref_name',
  address1: 'address1',
  address2: 'address2',
  title: 'title',
  title_short: 'title_short',
  shop_mail_1: 'shop_mail_1',
  shop_mail_2: 'shop_mail_2',
  tax_type: 'tax_type',
  tax: 'tax',
  tax_rounding_method: 'tax_rounding_method',
  reduce_tax_rate: 'reduce_tax_rate',
  shop_logo_url: 'shop_logo_url'
};

exports.Prisma.UgcSetScalarFieldEnum = {
  id: 'id',
  shopifyShopId: 'shopifyShopId',
  colormeShopId: 'colormeShopId',
  customShopId: 'customShopId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  targetElementId: 'targetElementId',
  targetPageUrl: 'targetPageUrl',
  ugcPanelType: 'ugcPanelType',
  startDate: 'startDate',
  endDate: 'endDate'
};

exports.Prisma.UgcSetDesignScalarFieldEnum = {
  id: 'id',
  ugcSetId: 'ugcSetId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  headline: 'headline',
  subheadline: 'subheadline',
  isChildrenSelectable: 'isChildrenSelectable',
  isGrid: 'isGrid',
  largeX: 'largeX',
  largeY: 'largeY',
  midX: 'midX',
  midY: 'midY',
  smallX: 'smallX',
  smallY: 'smallY',
  showCaption: 'showCaption',
  showCommentCount: 'showCommentCount',
  showLikeCount: 'showLikeCount'
};

exports.Prisma.UgcSetItemScalarFieldEnum = {
  id: 'id',
  ugcSetId: 'ugcSetId',
  mediaMediaId: 'mediaMediaId',
  childId: 'childId',
  storyMediaId: 'storyMediaId',
  reelMediaId: 'reelMediaId',
  tiktokMediaId: 'tiktokMediaId',
  isConsented: 'isConsented',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  order: 'order'
};

exports.Prisma.UgcInstagramPostListScalarFieldEnum = {
  id: 'id',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  listName: 'listName'
};

exports.Prisma.UgcInstagramPostListItemScalarFieldEnum = {
  id: 'id',
  listId: 'listId',
  childId: 'childId',
  mediaMediaId: 'mediaMediaId',
  storyMediaId: 'storyMediaId',
  reelMediaId: 'reelMediaId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  order: 'order'
};

exports.Prisma.ShopifyProductScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  shop_id: 'shop_id',
  product_id: 'product_id',
  title: 'title',
  vendor: 'vendor',
  product_type: 'product_type',
  handle: 'handle',
  published_at: 'published_at',
  status: 'status',
  published_scope: 'published_scope',
  tags: 'tags',
  imageUrl: 'imageUrl'
};

exports.Prisma.ShopifyVariantScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  variant_id: 'variant_id',
  product_id: 'product_id',
  title: 'title',
  price: 'price',
  sku: 'sku',
  position: 'position',
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  barcode: 'barcode',
  imageUrl: 'imageUrl'
};

exports.Prisma.ColormeProductScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  product_id: 'product_id',
  account_id: 'account_id',
  name: 'name',
  stocks: 'stocks',
  stock_managed: 'stock_managed',
  few_num: 'few_num',
  model_number: 'model_number',
  category: 'category',
  group_ids: 'group_ids',
  display_state: 'display_state',
  sales_price: 'sales_price',
  sales_price_including_tax: 'sales_price_including_tax',
  sales_price_tax: 'sales_price_tax',
  price: 'price',
  members_price: 'members_price',
  members_price_including_tax: 'members_price_including_tax',
  members_price_tax: 'members_price_tax',
  cost: 'cost',
  delivery_charge: 'delivery_charge',
  cool_charge: 'cool_charge',
  unavailable_payment_ids: 'unavailable_payment_ids',
  unavailable_delivery_ids: 'unavailable_delivery_ids',
  min_num: 'min_num',
  max_num: 'max_num',
  sale_start_date: 'sale_start_date',
  sale_end_date: 'sale_end_date',
  unit: 'unit',
  weight: 'weight',
  soldout_display: 'soldout_display',
  sort: 'sort',
  simple_expl: 'simple_expl',
  expl: 'expl',
  mobile_expl: 'mobile_expl',
  smartphone_expl: 'smartphone_expl',
  make_date: 'make_date',
  update_date: 'update_date',
  memo: 'memo',
  image_url: 'image_url',
  mobile_image_url: 'mobile_image_url',
  thumbnail_image_url: 'thumbnail_image_url',
  images: 'images',
  options: 'options',
  pickups: 'pickups',
  digital_content: 'digital_content',
  regular_purchase: 'regular_purchase',
  tax_reduced: 'tax_reduced',
  without_shipping: 'without_shipping'
};

exports.Prisma.ColormeVariantScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  product_id: 'product_id',
  account_id: 'account_id',
  option1_value: 'option1_value',
  option2_value: 'option2_value',
  title: 'title',
  stocks: 'stocks',
  few_num: 'few_num',
  model_number: 'model_number',
  option_price: 'option_price',
  option_price_including_tax: 'option_price_including_tax',
  option_price_tax: 'option_price_tax',
  option_members_price: 'option_members_price',
  option_members_price_including_tax: 'option_members_price_including_tax',
  option_members_price_tax: 'option_members_price_tax',
  make_date: 'make_date',
  update_date: 'update_date'
};

exports.Prisma.CustomShopScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ugc_website_id: 'ugc_website_id',
  name: 'name',
  domain: 'domain'
};

exports.Prisma.CustomProductScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  shop_id: 'shop_id',
  title: 'title',
  productUrl: 'productUrl',
  imageUrl: 'imageUrl',
  tags: 'tags'
};

exports.Prisma.CustomVariantScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  product_id: 'product_id',
  title: 'title',
  price: 'price',
  sku: 'sku',
  position: 'position',
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  barcode: 'barcode'
};

exports.Prisma.UgcShortUrlMapScalarFieldEnum = {
  id: 'id',
  version: 'version',
  actualUrl: 'actualUrl',
  shortUrl: 'shortUrl',
  participantId: 'participantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MainDashboardScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  timestamp: 'timestamp',
  platform: 'platform',
  campaignsCount: 'campaignsCount',
  usersCount: 'usersCount',
  averageFollowers: 'averageFollowers',
  totalFollowers: 'totalFollowers',
  usersWithChildren: 'usersWithChildren',
  usersGenderFemale: 'usersGenderFemale',
  usersGenderMale: 'usersGenderMale',
  usersGenderUnknown: 'usersGenderUnknown',
  usersAge10: 'usersAge10',
  usersAge20: 'usersAge20',
  usersAge30: 'usersAge30',
  usersAge40: 'usersAge40',
  usersAge50: 'usersAge50',
  usersAgeUnknown: 'usersAgeUnknown',
  usersByPrefecture: 'usersByPrefecture'
};

exports.Prisma.ContractScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  startDate: 'startDate',
  expirationDate: 'expirationDate',
  contractType: 'contractType'
};

exports.Prisma.CompanyScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  phone: 'phone',
  postalCode: 'postalCode',
  prefecture: 'prefecture',
  city: 'city',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  website: 'website'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  locale: 'locale',
  lastName: 'lastName',
  firstName: 'firstName',
  phone: 'phone',
  email: 'email',
  firebaseId: 'firebaseId',
  role: 'role',
  userStatus: 'userStatus',
  mfaRequired: 'mfaRequired'
};

exports.Prisma.UserInvitationScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  contractId: 'contractId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  firebaseId: 'firebaseId',
  email: 'email',
  invitationCode: 'invitationCode',
  expired: 'expired'
};

exports.Prisma.BrandScalarFieldEnum = {
  id: 'id',
  version: 'version',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  tag: 'tag',
  instagramUsername: 'instagramUsername',
  tiktokUsername: 'tiktokUsername',
  twitterUsername: 'twitterUsername'
};

exports.Prisma.BrandMediaScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  collection: 'collection',
  mediaUrl: 'mediaUrl',
  mimetype: 'mimetype'
};

exports.Prisma.UserPermissionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description'
};

exports.Prisma.UserRolePermissionLinkScalarFieldEnum = {
  id: 'id',
  version: 'version',
  permissionId: 'permissionId',
  role: 'role',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.OrientationScalarFieldEnum = {
  id: 'id',
  campaignName: 'campaignName',
  brandId: 'brandId',
  brandLogo: 'brandLogo',
  campaignType: 'campaignType',
  secondaryUsePermissionNeeded: 'secondaryUsePermissionNeeded',
  instagramNotifyAsAdvertisement: 'instagramNotifyAsAdvertisement',
  brandLogoUseAllowed: 'brandLogoUseAllowed',
  caseStudyAllowed: 'caseStudyAllowed',
  isVisitStore: 'isVisitStore',
  eventName: 'eventName',
  eventDateTime: 'eventDateTime',
  desiredCampaignPublishDateTime: 'desiredCampaignPublishDateTime',
  numberOfWinners: 'numberOfWinners',
  warehouseDeliveryDate: 'warehouseDeliveryDate',
  productMustBeReturned: 'productMustBeReturned',
  phoneNumber: 'phoneNumber',
  returnShippingPostalCode: 'returnShippingPostalCode',
  returnShippingAddressLine: 'returnShippingAddressLine',
  addressName: 'addressName',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.OrientationProductScalarFieldEnum = {
  id: 'id',
  productName: 'productName',
  quantityPerWinner: 'quantityPerWinner',
  isDrug: 'isDrug',
  isFoodMedicalProduct: 'isFoodMedicalProduct',
  shippingCount: 'shippingCount',
  skuName: 'skuName',
  productUrl: 'productUrl',
  price: 'price',
  isOpenPrice: 'isOpenPrice',
  orientationId: 'orientationId'
};

exports.Prisma.CampaignScalarFieldEnum = {
  id: 'id',
  uuid: 'uuid',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  projectCode: 'projectCode',
  type: 'type',
  isSecret: 'isSecret',
  winnerSelectionMethod: 'winnerSelectionMethod',
  isPresentMode: 'isPresentMode',
  isVisit: 'isVisit',
  status: 'status',
  rewardType: 'rewardType',
  hasFixedRewardPrice: 'hasFixedRewardPrice',
  rewardPerFollower: 'rewardPerFollower',
  fixedRewardPrice: 'fixedRewardPrice',
  description: 'description',
  maximumNumberOfWinners: 'maximumNumberOfWinners',
  targetGender: 'targetGender',
  targetRegion: 'targetRegion',
  targetAgeRange: 'targetAgeRange',
  targetTag: 'targetTag',
  targetAudience: 'targetAudience',
  isBirthdayRequired: 'isBirthdayRequired',
  isChildrenRequired: 'isChildrenRequired',
  hasProducts: 'hasProducts',
  isDirectShipping: 'isDirectShipping',
  isIdentificationRequired: 'isIdentificationRequired',
  boxName: 'boxName',
  boxPrice: 'boxPrice',
  boxIsOpenPrice: 'boxIsOpenPrice',
  publishingGuidelines: 'publishingGuidelines',
  dos: 'dos',
  donts: 'donts',
  startRegistrationDate: 'startRegistrationDate',
  endRegistrationDate: 'endRegistrationDate',
  endSelectingDate: 'endSelectingDate',
  startPublishDate: 'startPublishDate',
  endPublishDate: 'endPublishDate',
  minInstagramFollowers: 'minInstagramFollowers',
  minTikTokFollowers: 'minTikTokFollowers',
  expectedShippingDate: 'expectedShippingDate',
  representationCheckStatus: 'representationCheckStatus',
  isPresentCampaign: 'isPresentCampaign',
  presentCampaignType: 'presentCampaignType',
  isSemiPreCampaign: 'isSemiPreCampaign',
  isClientLegalCheck: 'isClientLegalCheck',
  winningMessageTemplateId: 'winningMessageTemplateId',
  avoidOffering: 'avoidOffering',
  memo: 'memo',
  isFileUploadedRequired: 'isFileUploadedRequired',
  isConfirmedPMDA: 'isConfirmedPMDA'
};

exports.Prisma.CampaignPerformanceReportScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  campaignId: 'campaignId',
  metadata: 'metadata'
};

exports.Prisma.CampaignRejectionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  reason: 'reason',
  completedDate: 'completedDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CampaignMediaScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  collection: 'collection',
  mediaUrl: 'mediaUrl',
  mimetype: 'mimetype'
};

exports.Prisma.CampaignBannerScalarFieldEnum = {
  id: 'id',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  startDate: 'startDate',
  endDate: 'endDate'
};

exports.Prisma.InfluencerChildrenScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  birthday: 'birthday',
  gender: 'gender'
};

exports.Prisma.ParticipantScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  influencerId: 'influencerId',
  campaignId: 'campaignId',
  status: 'status',
  tiktokId: 'tiktokId',
  instagramId: 'instagramId',
  twitterId: 'twitterId',
  addressId: 'addressId',
  isPicked: 'isPicked',
  isSendEmail: 'isSendEmail',
  isSendPrivateOffer: 'isSendPrivateOffer',
  isSecondaryUseAccepted: 'isSecondaryUseAccepted',
  isIdentificationAccepted: 'isIdentificationAccepted',
  isPreQuestionnaireAccepted: 'isPreQuestionnaireAccepted',
  isPreventResaleAccepted: 'isPreventResaleAccepted',
  memo: 'memo',
  rewardPrice: 'rewardPrice',
  twitterUsername: 'twitterUsername',
  mediaUrls: 'mediaUrls'
};

exports.Prisma.ParticipantSkuLinkScalarFieldEnum = {
  id: 'id',
  version: 'version',
  participantId: 'participantId',
  skuId: 'skuId',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ParticipantRewardScalarFieldEnum = {
  id: 'id',
  participantId: 'participantId',
  campaignId: 'campaignId',
  influencerId: 'influencerId',
  taxWithholdingId: 'taxWithholdingId',
  consumptionTaxId: 'consumptionTaxId',
  consumptionBurdenRateId: 'consumptionBurdenRateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  confirmedAt: 'confirmedAt',
  rewardAmount: 'rewardAmount',
  consumptionTaxAmount: 'consumptionTaxAmount',
  priceIncludingTax: 'priceIncludingTax',
  withholdingAmount: 'withholdingAmount',
  paymentAmount: 'paymentAmount',
  reportCreatedDate: 'reportCreatedDate',
  targetMonth: 'targetMonth',
  paymentDate: 'paymentDate',
  ignoreForAnnualReport: 'ignoreForAnnualReport',
  paymentMemo: 'paymentMemo',
  calculationType: 'calculationType'
};

exports.Prisma.CreatorRewardScalarFieldEnum = {
  id: 'id',
  influencerId: 'influencerId',
  taxWithholdingId: 'taxWithholdingId',
  consumptionTaxId: 'consumptionTaxId',
  consumptionBurdenRateId: 'consumptionBurdenRateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  rewardAmount: 'rewardAmount',
  consumptionTaxAmount: 'consumptionTaxAmount',
  priceIncludingTax: 'priceIncludingTax',
  withholdingAmount: 'withholdingAmount',
  paymentAmount: 'paymentAmount',
  reportCreatedDate: 'reportCreatedDate',
  targetMonth: 'targetMonth',
  scheduledPaymentDate: 'scheduledPaymentDate',
  paymentDate: 'paymentDate',
  ignoreForAnnualReport: 'ignoreForAnnualReport',
  rewardMemo: 'rewardMemo',
  calculationType: 'calculationType',
  invoiceUrl: 'invoiceUrl'
};

exports.Prisma.PaymentPlatformFeeScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  paymentPlatform: 'paymentPlatform',
  fee: 'fee'
};

exports.Prisma.AddressScalarFieldEnum = {
  id: 'id',
  influencerId: 'influencerId',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  postalCode: 'postalCode',
  prefecture: 'prefecture',
  city: 'city',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2'
};

exports.Prisma.CreativeScalarFieldEnum = {
  id: 'id',
  version: 'version',
  participantId: 'participantId',
  taskId: 'taskId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaMediaId: 'mediaMediaId',
  storyMediaId: 'storyMediaId',
  tiktokVideoId: 'tiktokVideoId',
  instagramUserReelId: 'instagramUserReelId',
  tweetId: 'tweetId',
  screenshotCompleted: 'screenshotCompleted',
  usageDays: 'usageDays',
  usageDeadline: 'usageDeadline',
  legacyTaskType: 'legacyTaskType',
  legacyCreativeUrl: 'legacyCreativeUrl',
  legacyInsights: 'legacyInsights',
  legacyPostMeta: 'legacyPostMeta',
  classification: 'classification',
  isPaidPromotionTagCompliant: 'isPaidPromotionTagCompliant',
  isdeletedFromSocialMedia: 'isdeletedFromSocialMedia',
  insightScreenshots: 'insightScreenshots'
};

exports.Prisma.InfluencerScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  firebaseId: 'firebaseId',
  email: 'email',
  lastName: 'lastName',
  lastNameHash: 'lastNameHash',
  firstName: 'firstName',
  firstNameHash: 'firstNameHash',
  phone: 'phone',
  birthday: 'birthday',
  gender: 'gender',
  bio: 'bio',
  authType: 'authType',
  sendbirdToken: 'sendbirdToken',
  chatUnreadCount: 'chatUnreadCount',
  introduction: 'introduction',
  tags: 'tags',
  phoneNumberVerified: 'phoneNumberVerified',
  identificationProcessStatus: 'identificationProcessStatus',
  identificationResultStatus: 'identificationResultStatus',
  amlRiskCheckProcessStatus: 'amlRiskCheckProcessStatus',
  amlRiskCheckResultStatus: 'amlRiskCheckResultStatus',
  childrenStatus: 'childrenStatus',
  credibilityScore: 'credibilityScore',
  credibilityScoreTier: 'credibilityScoreTier',
  isAdmin: 'isAdmin',
  isBlacklisted: 'isBlacklisted',
  isContractConcluded: 'isContractConcluded',
  currentInstagramId: 'currentInstagramId',
  currentTikTokId: 'currentTikTokId',
  currentTwitterId: 'currentTwitterId',
  twitterUsernameList: 'twitterUsernameList'
};

exports.Prisma.InfluencerMediaScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  collection: 'collection',
  mediaUrl: 'mediaUrl',
  mimetype: 'mimetype'
};

exports.Prisma.TwitterScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  twitterId: 'twitterId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.TwitterUserScalarFieldEnum = {
  id: 'id',
  version: 'version',
  twitterId: 'twitterId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  username: 'username',
  protected: 'protected',
  verified: 'verified',
  description: 'description',
  profileImageUrl: 'profileImageUrl',
  url: 'url',
  publicMetrics: 'publicMetrics'
};

exports.Prisma.TwitterTweetScalarFieldEnum = {
  id: 'id',
  version: 'version',
  authorId: 'authorId',
  tweetId: 'tweetId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  text: 'text',
  lang: 'lang',
  conversationId: 'conversationId',
  publicMetrics: 'publicMetrics',
  editHistoryTweetIds: 'editHistoryTweetIds',
  entities: 'entities',
  attachments: 'attachments',
  media: 'media',
  users: 'users',
  note_tweet: 'note_tweet'
};

exports.Prisma.InstagramScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  igId: 'igId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.InstagramUserScalarFieldEnum = {
  id: 'id',
  version: 'version',
  igId: 'igId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  accountId: 'accountId',
  accountToken: 'accountToken',
  username: 'username',
  name: 'name',
  website: 'website',
  biography: 'biography',
  photoUrl: 'photoUrl',
  revoked: 'revoked',
  followersCount: 'followersCount',
  followsCount: 'followsCount',
  mediaCount: 'mediaCount'
};

exports.Prisma.InstagramUserMediaScalarFieldEnum = {
  id: 'id',
  version: 'version',
  igId: 'igId',
  mediaId: 'mediaId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaType: 'mediaType',
  permalink: 'permalink',
  timestamp: 'timestamp',
  username: 'username',
  mediaUrl: 'mediaUrl',
  thumbnailUrl: 'thumbnailUrl',
  caption: 'caption',
  commentsCount: 'commentsCount',
  likeCount: 'likeCount',
  isLemonSquare: 'isLemonSquare',
  hashtags: 'hashtags',
  mentions: 'mentions',
  totalInteractions: 'totalInteractions',
  engagement: 'engagement',
  impressions: 'impressions',
  reach: 'reach',
  saved: 'saved',
  shares: 'shares',
  videoViews: 'videoViews',
  follows: 'follows',
  profileActivity: 'profileActivity',
  profileVisits: 'profileVisits',
  followersCount: 'followersCount',
  engagementRate: 'engagementRate',
  hasLSAttribution: 'hasLSAttribution',
  hasLSMentions: 'hasLSMentions',
  hasLSHashTags: 'hasLSHashTags',
  imageHasText: 'imageHasText',
  children: 'children',
  scrapedAt: 'scrapedAt'
};

exports.Prisma.InstagramUserStoryScalarFieldEnum = {
  id: 'id',
  version: 'version',
  igId: 'igId',
  mediaId: 'mediaId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaType: 'mediaType',
  permalink: 'permalink',
  timestamp: 'timestamp',
  username: 'username',
  mediaUrl: 'mediaUrl',
  thumbnailUrl: 'thumbnailUrl',
  caption: 'caption',
  commentsCount: 'commentsCount',
  likeCount: 'likeCount',
  isLemonSquare: 'isLemonSquare',
  hashtags: 'hashtags',
  mentions: 'mentions',
  exits: 'exits',
  impressions: 'impressions',
  reach: 'reach',
  replies: 'replies',
  tapsBack: 'tapsBack',
  tapsForward: 'tapsForward',
  swipeForward: 'swipeForward',
  shares: 'shares',
  follows: 'follows',
  profileActivity: 'profileActivity',
  profileVisits: 'profileVisits',
  scrapedAt: 'scrapedAt'
};

exports.Prisma.InstagramUserReelScalarFieldEnum = {
  id: 'id',
  version: 'version',
  igId: 'igId',
  mediaId: 'mediaId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaType: 'mediaType',
  permalink: 'permalink',
  timestamp: 'timestamp',
  username: 'username',
  mediaUrl: 'mediaUrl',
  thumbnailUrl: 'thumbnailUrl',
  caption: 'caption',
  commentsCount: 'commentsCount',
  likeCount: 'likeCount',
  isLemonSquare: 'isLemonSquare',
  hashtags: 'hashtags',
  mentions: 'mentions',
  comments: 'comments',
  likes: 'likes',
  plays: 'plays',
  reach: 'reach',
  saved: 'saved',
  shares: 'shares',
  totalInteractions: 'totalInteractions',
  avgWatchTime: 'avgWatchTime',
  videoViewTotalTime: 'videoViewTotalTime',
  scrapedAt: 'scrapedAt'
};

exports.Prisma.InstagramAudienceScalarFieldEnum = {
  id: 'id',
  version: 'version',
  igId: 'igId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  genders: 'genders',
  geoCities: 'geoCities',
  geoCountries: 'geoCountries',
  ages: 'ages',
  gendersPerAge: 'gendersPerAge'
};

exports.Prisma.TikTokScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  openId: 'openId'
};

exports.Prisma.TikTokUserScalarFieldEnum = {
  id: 'id',
  version: 'version',
  openId: 'openId',
  unionId: 'unionId',
  clientKey: 'clientKey',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  displayName: 'displayName',
  tiktokUsername: 'tiktokUsername',
  tiktokProfileUrl: 'tiktokProfileUrl',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  scope: 'scope',
  revoked: 'revoked',
  estimatedFollowers: 'estimatedFollowers',
  estimatedFollowing: 'estimatedFollowing',
  estimatedLikes: 'estimatedLikes'
};

exports.Prisma.TikTokUserVideoScalarFieldEnum = {
  id: 'id',
  version: 'version',
  openId: 'openId',
  videoId: 'videoId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  videoDescription: 'videoDescription',
  tiktokUsername: 'tiktokUsername',
  tiktokVideoUrl: 'tiktokVideoUrl',
  tiktokProfileUrl: 'tiktokProfileUrl',
  coverImageUrl: 'coverImageUrl',
  videoUrl: 'videoUrl',
  shareUrl: 'shareUrl',
  postedAt: 'postedAt',
  duration: 'duration',
  height: 'height',
  width: 'width',
  commentsCount: 'commentsCount',
  likeCount: 'likeCount',
  shareCount: 'shareCount',
  viewCount: 'viewCount'
};

exports.Prisma.TaskScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  taskGroupId: 'taskGroupId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  platform: 'platform',
  taskType: 'taskType',
  taskDetail: 'taskDetail',
  hashtag: 'hashtag',
  mention: 'mention',
  link: 'link',
  caption: 'caption',
  hasPretask: 'hasPretask',
  startDraftDate: 'startDraftDate',
  deadlineDraftDate: 'deadlineDraftDate',
  isPretaskClosed: 'isPretaskClosed',
  isRequired: 'isRequired',
  allowTreePosts: 'allowTreePosts'
};

exports.Prisma.TaskGroupScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  requiredTaskCount: 'requiredTaskCount'
};

exports.Prisma.PretaskScalarFieldEnum = {
  id: 'id',
  taskId: 'taskId',
  participantId: 'participantId',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  caption: 'caption',
  mediaUrls: 'mediaUrls',
  bgmInfo: 'bgmInfo',
  adminCheckStatus: 'adminCheckStatus',
  clientCheckStatus: 'clientCheckStatus',
  lawCheckStatus: 'lawCheckStatus',
  adminMemo: 'adminMemo',
  clientMemo: 'clientMemo',
  lawMemo: 'lawMemo',
  lawCheckDate: 'lawCheckDate',
  lawCheckUserId: 'lawCheckUserId',
  isSubmit: 'isSubmit'
};

exports.Prisma.PretaskDeniedReasonScalarFieldEnum = {
  id: 'id',
  pretaskId: 'pretaskId',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  modificationContent: 'modificationContent',
  sender: 'sender'
};

exports.Prisma.SnsPretaskScalarFieldEnum = {
  id: 'id',
  taskId: 'taskId',
  participantId: 'participantId',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  caption: 'caption',
  mediaUrls: 'mediaUrls',
  bgmInfo: 'bgmInfo',
  status: 'status',
  memo: 'memo'
};

exports.Prisma.SnsPretaskInspectionScalarFieldEnum = {
  id: 'id',
  snsPretaskId: 'snsPretaskId',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  result: 'result',
  message: 'message',
  reviewer: 'reviewer',
  reviewerId: 'reviewerId',
  reviewedAt: 'reviewedAt'
};

exports.Prisma.ProductScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name'
};

exports.Prisma.SkuScalarFieldEnum = {
  id: 'id',
  version: 'version',
  productId: 'productId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  productNumber: 'productNumber',
  clientProductNumber: 'clientProductNumber'
};

exports.Prisma.CampaignSkuLinkScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  skuId: 'skuId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  numberDistributedPerPerson: 'numberDistributedPerPerson',
  numberOfWinners: 'numberOfWinners',
  memo: 'memo',
  isSendProduct: 'isSendProduct',
  productDetailGroupId: 'productDetailGroupId'
};

exports.Prisma.ArrivalProductScalarFieldEnum = {
  id: 'id',
  version: 'version',
  skuId: 'skuId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  numberOfArrival: 'numberOfArrival'
};

exports.Prisma.ShippingProductScalarFieldEnum = {
  id: 'id',
  version: 'version',
  skuId: 'skuId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  recipient: 'recipient',
  numberOfPassingInspection: 'numberOfPassingInspection',
  numberOfDefectives: 'numberOfDefectives'
};

exports.Prisma.InspectionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  arrivalProductId: 'arrivalProductId',
  skuId: 'skuId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  status: 'status',
  numberOfPassingInspection: 'numberOfPassingInspection',
  numberOfDefectives: 'numberOfDefectives',
  numberOfArrivals: 'numberOfArrivals',
  memo: 'memo',
  inspectedAt: 'inspectedAt'
};

exports.Prisma.StockScalarFieldEnum = {
  id: 'id',
  version: 'version',
  skuId: 'skuId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  numberOfUninspected: 'numberOfUninspected',
  numberOfPassingInspection: 'numberOfPassingInspection',
  numberOfDefectives: 'numberOfDefectives',
  totalUninspected: 'totalUninspected',
  totalPassings: 'totalPassings',
  totalDefectives: 'totalDefectives',
  memo: 'memo'
};

exports.Prisma.QuestionnaireScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  answerType: 'answerType',
  timingType: 'timingType',
  order: 'order',
  questionText: 'questionText',
  inactive: 'inactive'
};

exports.Prisma.QuestionnaireOptionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  questionnaireId: 'questionnaireId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  order: 'order',
  optionText: 'optionText',
  temp_model_id: 'temp_model_id',
  temp_old_option_id: 'temp_old_option_id',
  temp_model_type: 'temp_model_type'
};

exports.Prisma.ParticipantQuestionnaireAnswerScalarFieldEnum = {
  id: 'id',
  questionnaireId: 'questionnaireId',
  participantId: 'participantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  answerType: 'answerType',
  answer: 'answer'
};

exports.Prisma.SendbirdBrandUserScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  token: 'token'
};

exports.Prisma.SendbirdChannelScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandUserId: 'brandUserId',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  channelUrl: 'channelUrl',
  brandLastSeenTimestamp: 'brandLastSeenTimestamp',
  brandLastMessageTimestamp: 'brandLastMessageTimestamp',
  influencerLastMessageTimestamp: 'influencerLastMessageTimestamp',
  latestMessageId: 'latestMessageId',
  markAsUnread: 'markAsUnread'
};

exports.Prisma.SendbirdQueueScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  groupId: 'groupId',
  senderId: 'senderId',
  receiverId: 'receiverId',
  message: 'message',
  status: 'status',
  retryCount: 'retryCount'
};

exports.Prisma.SendbirdTemplateMessageScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  message: 'message'
};

exports.Prisma.MailgunQueueScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  queueId: 'queueId',
  mailType: 'mailType',
  campaignId: 'campaignId',
  influencerId: 'influencerId',
  userId: 'userId',
  status: 'status',
  template: 'template',
  errorMessage: 'errorMessage',
  variables: 'variables'
};

exports.Prisma.InfluencerTempIdentificationScalarFieldEnum = {
  identificationId: 'identificationId',
  identificationPublicId: 'identificationPublicId',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  identificationProcessStatus: 'identificationProcessStatus',
  acceptedAt: 'acceptedAt',
  plansSelectedAt: 'plansSelectedAt',
  comparingData: 'comparingData'
};

exports.Prisma.InfluencerIdentificationScalarFieldEnum = {
  identificationId: 'identificationId',
  identificationPublicId: 'identificationPublicId',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  identificationProcessStatus: 'identificationProcessStatus',
  identificationResultStatus: 'identificationResultStatus',
  acceptedAt: 'acceptedAt',
  plansSelectedAt: 'plansSelectedAt',
  documentSubmittedAt: 'documentSubmittedAt',
  preparedAt: 'preparedAt',
  verifiedAt: 'verifiedAt',
  comparingData: 'comparingData',
  records: 'records'
};

exports.Prisma.InfluencerIdentificationFailedReasonScalarFieldEnum = {
  id: 'id',
  identificationId: 'identificationId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  identificationFailedTarget: 'identificationFailedTarget',
  identificationFailedCode: 'identificationFailedCode',
  detail: 'detail'
};

exports.Prisma.FanScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  tiktokId: 'tiktokId',
  instagramId: 'instagramId',
  fanListId: 'fanListId',
  fanScore: 'fanScore'
};

exports.Prisma.FanListScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  brandId: 'brandId',
  listName: 'listName'
};

exports.Prisma.FanListItemScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  fanListId: 'fanListId',
  username: 'username',
  igId: 'igId'
};

exports.Prisma.UnregisteredFanScalarFieldEnum = {
  id: 'id',
  version: 'version',
  username: 'username',
  fanListId: 'fanListId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MonthlyFanListScoreScalarFieldEnum = {
  id: 'id',
  version: 'version',
  fanListId: 'fanListId',
  agregetedScore: 'agregetedScore',
  agregetedDate: 'agregetedDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.InfluencerAmlRiskCheckScalarFieldEnum = {
  amlRiskCheckId: 'amlRiskCheckId',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  amlRiskCheckProcessStatus: 'amlRiskCheckProcessStatus',
  amlRiskCheckResultStatus: 'amlRiskCheckResultStatus',
  needConfirmationDetail: 'needConfirmationDetail',
  sendConfirmationMail: 'sendConfirmationMail',
  acceptedAt: 'acceptedAt',
  completedAt: 'completedAt'
};

exports.Prisma.MasterAmlRiskCheckProcessStatusScalarFieldEnum = {
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterAmlRiskCheckResultStatusScalarFieldEnum = {
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterIdentificationProcessStatusScalarFieldEnum = {
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterIdentificationResultStatusScalarFieldEnum = {
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterIdentificationFailedTargetScalarFieldEnum = {
  target: 'target',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterIdentificationFailedCodeScalarFieldEnum = {
  code: 'code',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterTaxWithholdingScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  taxRate: 'taxRate'
};

exports.Prisma.MasterConsumptionTaxScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  description: 'description',
  taxRate: 'taxRate'
};

exports.Prisma.MasterConsumptionTaxBurdenRateScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  recipientType: 'recipientType',
  description: 'description',
  burdenRate: 'burdenRate'
};

exports.Prisma.MasterPaymentPlatformScalarFieldEnum = {
  platform: 'platform',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.MasterBankScalarFieldEnum = {
  code: 'code',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  order: 'order'
};

exports.Prisma.BankAccountScalarFieldEnum = {
  id: 'id',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  bankCode: 'bankCode',
  branchCode: 'branchCode',
  accountType: 'accountType',
  accountNumber: 'accountNumber',
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'companyName',
  isActive: 'isActive',
  isVerified: 'isVerified',
  verifiedAt: 'verifiedAt',
  memo: 'memo'
};

exports.Prisma.SystemNotificationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  link: 'link',
  target: 'target',
  startPublishDate: 'startPublishDate',
  endPublishDate: 'endPublishDate'
};

exports.Prisma.MobileAppNewsFeedNotificationTemplateScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  name: 'name',
  content: 'content',
  ctaUrl: 'ctaUrl',
  startPublishDate: 'startPublishDate',
  endPublishDate: 'endPublishDate',
  triggerId: 'triggerId',
  lastTriggeredUserId: 'lastTriggeredUserId'
};

exports.Prisma.MobileAppCampaignTasksReminderNotificationTemplateScalarFieldEnum = {
  id: 'id',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  name: 'name',
  type: 'type',
  payload: 'payload',
  triggerId: 'triggerId',
  lastTriggeredUserId: 'lastTriggeredUserId'
};

exports.Prisma.TwitterReportQueueScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  status: 'status'
};

exports.Prisma.UgcConsentScalarFieldEnum = {
  id: 'id',
  version: 'version',
  brandId: 'brandId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaMediaId: 'mediaMediaId',
  reelMediaId: 'reelMediaId',
  consentUuid: 'consentUuid',
  consentedAt: 'consentedAt',
  ugcConsentItemId: 'ugcConsentItemId'
};

exports.Prisma.InfluencerInvoiceInfoScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  invoiceNumber: 'invoiceNumber',
  invoiceStatus: 'invoiceStatus',
  addressMatched: 'addressMatched',
  nameMatched: 'nameMatched',
  useCompanyName: 'useCompanyName',
  invoiceApiResponse: 'invoiceApiResponse',
  invoiceApiRequestDate: 'invoiceApiRequestDate',
  influencerComparedData: 'influencerComparedData',
  invoiceInfoMemo: 'invoiceInfoMemo',
  expiredAt: 'expiredAt'
};

exports.Prisma.ParticipantInvoiceScalarFieldEnum = {
  id: 'id',
  version: 'version',
  campaignId: 'campaignId',
  influencerId: 'influencerId',
  participantId: 'participantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  fileUrl: 'fileUrl',
  isActive: 'isActive'
};

exports.Prisma.AnnualPaymentReportScalarFieldEnum = {
  id: 'id',
  version: 'version',
  influencerId: 'influencerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  targetYear: 'targetYear',
  rewardAmount: 'rewardAmount',
  rewardAmountIncludingTax: 'rewardAmountIncludingTax',
  withholdingAmount: 'withholdingAmount',
  paymentAmount: 'paymentAmount',
  unpaidWithholdingAmount: 'unpaidWithholdingAmount',
  unpaidRewardAmountIncludingTax: 'unpaidRewardAmountIncludingTax',
  status: 'status',
  approvedAt: 'approvedAt',
  fileUrl: 'fileUrl',
  memo: 'memo'
};

exports.Prisma.CreativeImageTextInfoScalarFieldEnum = {
  id: 'id',
  version: 'version',
  creativeId: 'creativeId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  mediaUrlUserWrittenText: 'mediaUrlUserWrittenText',
  mediaUrlTextOnProduct: 'mediaUrlTextOnProduct',
  childrenImageText: 'childrenImageText'
};

exports.Prisma.OriginalUrlScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  campaignId: 'campaignId',
  url: 'url'
};

exports.Prisma.CountriesScalarFieldEnum = {
  id: 'id',
  sortname: 'sortname',
  name: 'name',
  phonecode: 'phonecode'
};

exports.Prisma.StatesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  country_id: 'country_id'
};

exports.Prisma.CitiesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  state_id: 'state_id'
};

exports.Prisma.UgcSdkScalarFieldEnum = {
  id: 'id',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.UgcWebsiteEnvironment = {
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION',
  DEMO: 'DEMO',
  STAGING: 'STAGING',
  DEVELOPMENT: 'DEVELOPMENT'
};

exports.UgcPanelType = {
  INSTAGRAM_POST: 'INSTAGRAM_POST',
  INSTAGRAM_REEL: 'INSTAGRAM_REEL',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  TIKTOK_VIDEO: 'TIKTOK_VIDEO'
};

exports.DashboardPlatform = {
  TikTok: 'TikTok',
  Instagram: 'Instagram'
};

exports.ContractType = {
  SAAS: 'SAAS',
  BRAND_SUPPORT: 'BRAND_SUPPORT'
};

exports.UserRole = {
  ADMIN: 'ADMIN',
  OPERATOR: 'OPERATOR',
  SALES: 'SALES',
  CLIENT_OWNER: 'CLIENT_OWNER',
  CLIENT_MEMBER: 'CLIENT_MEMBER',
  CLIENT_LEGAL: 'CLIENT_LEGAL',
  WAREHOUSE: 'WAREHOUSE',
  LEGAL: 'LEGAL',
  BRAND_AUDITOR: 'BRAND_AUDITOR',
  NONE: 'NONE'
};

exports.UserStatus = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED'
};

exports.CampaignType = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};

exports.CaseStudyOptions = {
  ONLY_HP: 'ONLY_HP',
  MATERIALS_ONLY: 'MATERIALS_ONLY',
  BOTH: 'BOTH',
  NO_CASE: 'NO_CASE'
};

exports.ShouldProductBeReturnedOptions = {
  RETURN: 'RETURN',
  DISPOSE: 'DISPOSE',
  NEXT_CAMPAIGN: 'NEXT_CAMPAIGN'
};

exports.WinnerSelectionMethod = {
  FREE_SELECTION: 'FREE_SELECTION',
  FIRST_COME_FIRST_SERVED: 'FIRST_COME_FIRST_SERVED',
  OTHER: 'OTHER'
};

exports.CampaignStatus = {
  DRAFT: 'DRAFT',
  SUBMISSION: 'SUBMISSION',
  APPROVED: 'APPROVED',
  READY: 'READY',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  REJECT: 'REJECT',
  ARCHIVED: 'ARCHIVED'
};

exports.RewardType = {
  NONE: 'NONE',
  GIFTING: 'GIFTING',
  PAID_PROMOTION: 'PAID_PROMOTION'
};

exports.TargetGenderType = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  ALL: 'ALL'
};

exports.TargetAgeRange = {
  NONE: 'NONE',
  RANGE_10_19: 'RANGE_10_19',
  RANGE_20_29: 'RANGE_20_29',
  RANGE_30_39: 'RANGE_30_39',
  RANGE_40_49: 'RANGE_40_49'
};

exports.RepresentationCheckStatus = {
  UNREVIEWED: 'UNREVIEWED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED'
};

exports.TargetSnsType = {
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER'
};

exports.InfluencerChildrenGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  NOT_KNOWN: 'NOT_KNOWN'
};

exports.ParticipantStatus = {
  INVITED: 'INVITED',
  JOINED: 'JOINED',
  DEAL: 'DEAL',
  PARTICIPANT_WITHDRAW: 'PARTICIPANT_WITHDRAW',
  BRAND_REJECT: 'BRAND_REJECT'
};

exports.RewardCalculationType = {
  SOLO_PROPRIETOR_INVOICE: 'SOLO_PROPRIETOR_INVOICE',
  COMPANY_INVOICE: 'COMPANY_INVOICE',
  COMPANY_NO_INVOICE: 'COMPANY_NO_INVOICE',
  OTHER: 'OTHER'
};

exports.AddressType = {
  IDENTIFICATION: 'IDENTIFICATION',
  SHIPPING: 'SHIPPING',
  OTHER: 'OTHER'
};

exports.Classification = {
  APP_ISSUE: 'APP_ISSUE',
  MISSING_REPORT: 'MISSING_REPORT'
};

exports.InfluencerGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

exports.ChildrenStatus = {
  NO_REGISTERED: 'NO_REGISTERED',
  NO_CHILDREN: 'NO_CHILDREN',
  HAS_CHILDREN: 'HAS_CHILDREN'
};

exports.InstagramMediaType = {
  IMAGE: 'IMAGE',
  CAROUSEL_ALBUM: 'CAROUSEL_ALBUM',
  VIDEO: 'VIDEO',
  REELS: 'REELS'
};

exports.InstagramStoryType = {
  STORY_IMAGE: 'STORY_IMAGE',
  STORY_VIDEO: 'STORY_VIDEO'
};

exports.InstagramReelType = {
  REEL_VIDEO: 'REEL_VIDEO'
};

exports.CreativePlatform = {
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
  TWITTER: 'TWITTER',
  OTHER: 'OTHER'
};

exports.CreativeTaskType = {
  INSTAGRAM_POST: 'INSTAGRAM_POST',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTAGRAM_REEL: 'INSTAGRAM_REEL',
  TIKTOK_VIDEO: 'TIKTOK_VIDEO',
  TWITTER_POST: 'TWITTER_POST',
  OTHER: 'OTHER'
};

exports.PretaskCheckStatus = {
  UNREVIEWED: 'UNREVIEWED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  DENIED_ALL: 'DENIED_ALL',
  SKIPPED: 'SKIPPED'
};

exports.DeniedReasonSender = {
  OTHER: 'OTHER',
  CLIENT: 'CLIENT',
  ADMIN: 'ADMIN'
};

exports.SnsPretaskCheckStatus = {
  LEGAL_REVIEWING: 'LEGAL_REVIEWING',
  CLIENT_REVIEWING: 'CLIENT_REVIEWING',
  ADMIN_REVIEWING: 'ADMIN_REVIEWING',
  FINISHED: 'FINISHED'
};

exports.SnsPretaskInspectionResult = {
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  DENIED: 'DENIED'
};

exports.SnsPretaskReviewer = {
  OTHER: 'OTHER',
  CLIENT: 'CLIENT',
  ADMIN: 'ADMIN'
};

exports.RecipientType = {
  CLIENT: 'CLIENT',
  INFLUENCER: 'INFLUENCER',
  OTHER: 'OTHER'
};

exports.InspectionStatus = {
  BEFORE_ARRIVAL: 'BEFORE_ARRIVAL',
  ARRIVED: 'ARRIVED',
  INSPECTED: 'INSPECTED'
};

exports.StockType = {
  ARRIVAL: 'ARRIVAL',
  INSPECTION: 'INSPECTION',
  DELIVERY: 'DELIVERY',
  OTHER: 'OTHER'
};

exports.AnswerType = {
  TEXT: 'TEXT',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX'
};

exports.TimingType = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER'
};

exports.SendbirdMessageType = {
  CAMPAIGN: 'CAMPAIGN',
  INFLUENCER_LIST: 'INFLUENCER_LIST'
};

exports.SendbirdMessageQueueStatus = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED'
};

exports.MailgunMessageType = {
  CAMPAIGN_WINNING: 'CAMPAIGN_WINNING',
  INVITE_CAMPAIGN: 'INVITE_CAMPAIGN',
  SENDBIRD_NOTIFICATION: 'SENDBIRD_NOTIFICATION',
  THANKS_REGISTRATION: 'THANKS_REGISTRATION',
  APPROVE_PRETASK: 'APPROVE_PRETASK',
  REJECT_PRETASK: 'REJECT_PRETASK',
  MONTHLY_PAIMENT_REPORT_FOR_OPE: 'MONTHLY_PAIMENT_REPORT_FOR_OPE'
};

exports.MailgunMessageQueueStatus = {
  ACCEPTED: 'ACCEPTED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED'
};

exports.ConsumptionTaxType = {
  STANDARD: 'STANDARD',
  REDUCED: 'REDUCED'
};

exports.BusinessType = {
  VERIFIED_SOLO_PROPRIETOR: 'VERIFIED_SOLO_PROPRIETOR',
  VERIFIED_COMPANY: 'VERIFIED_COMPANY',
  OTHERS: 'OTHERS'
};

exports.BankAccountType = {
  SAVINGS: 'SAVINGS',
  CHECKING: 'CHECKING',
  OTHER: 'OTHER'
};

exports.NotificationTarget = {
  CMS: 'CMS',
  APP: 'APP'
};

exports.CampaignTaskReminderType = {
  MISSING_PAID_PARTNERSHIP_TAG: 'MISSING_PAID_PARTNERSHIP_TAG',
  MISSING_MEDIA_POST: 'MISSING_MEDIA_POST',
  MISSING_PRETASK: 'MISSING_PRETASK',
  MISSING_POST_QUESTIONNAIRE: 'MISSING_POST_QUESTIONNAIRE'
};

exports.ReportJobStatus = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED'
};

exports.InvoiceStatusType = {
  VERIFIED: 'VERIFIED',
  DENIED: 'DENIED',
  DELETED: 'DELETED'
};

exports.ReportStatus = {
  UNREVIEWED: 'UNREVIEWED',
  APPROVED: 'APPROVED',
  MISSING_DATA: 'MISSING_DATA',
  REPORT_GENERATED: 'REPORT_GENERATED',
  PUBLISHED: 'PUBLISHED',
  OTHER: 'OTHER'
};

exports.Prisma.ModelName = {
  ElasticSearchMigration: 'ElasticSearchMigration',
  NatsMigration: 'NatsMigration',
  DailyUgcEventStats: 'DailyUgcEventStats',
  DailyFirstLastClickConversion: 'DailyFirstLastClickConversion',
  DailyFirstLastClickConversionPostWise: 'DailyFirstLastClickConversionPostWise',
  DailyUgcClickPostWise: 'DailyUgcClickPostWise',
  DailyCartin: 'DailyCartin',
  DailyCartinPostWise: 'DailyCartinPostWise',
  DailyAverragePanelOpenDuration: 'DailyAverragePanelOpenDuration',
  DailyUgcPageviewStats: 'DailyUgcPageviewStats',
  UgcStore: 'UgcStore',
  ShopifySession: 'ShopifySession',
  ShopifyPixel: 'ShopifyPixel',
  ShopifyShop: 'ShopifyShop',
  ColormeShop: 'ColormeShop',
  UgcSet: 'UgcSet',
  UgcSetDesign: 'UgcSetDesign',
  UgcSetItem: 'UgcSetItem',
  UgcInstagramPostList: 'UgcInstagramPostList',
  UgcInstagramPostListItem: 'UgcInstagramPostListItem',
  ShopifyProduct: 'ShopifyProduct',
  ShopifyVariant: 'ShopifyVariant',
  ColormeProduct: 'ColormeProduct',
  ColormeVariant: 'ColormeVariant',
  CustomShop: 'CustomShop',
  CustomProduct: 'CustomProduct',
  CustomVariant: 'CustomVariant',
  UgcShortUrlMap: 'UgcShortUrlMap',
  MainDashboard: 'MainDashboard',
  Contract: 'Contract',
  Company: 'Company',
  User: 'User',
  UserInvitation: 'UserInvitation',
  Brand: 'Brand',
  BrandMedia: 'BrandMedia',
  UserPermission: 'UserPermission',
  UserRolePermissionLink: 'UserRolePermissionLink',
  Orientation: 'Orientation',
  OrientationProduct: 'OrientationProduct',
  Campaign: 'Campaign',
  CampaignPerformanceReport: 'CampaignPerformanceReport',
  CampaignRejection: 'CampaignRejection',
  CampaignMedia: 'CampaignMedia',
  CampaignBanner: 'CampaignBanner',
  InfluencerChildren: 'InfluencerChildren',
  Participant: 'Participant',
  ParticipantSkuLink: 'ParticipantSkuLink',
  ParticipantReward: 'ParticipantReward',
  CreatorReward: 'CreatorReward',
  PaymentPlatformFee: 'PaymentPlatformFee',
  Address: 'Address',
  Creative: 'Creative',
  Influencer: 'Influencer',
  InfluencerMedia: 'InfluencerMedia',
  Twitter: 'Twitter',
  TwitterUser: 'TwitterUser',
  TwitterTweet: 'TwitterTweet',
  Instagram: 'Instagram',
  InstagramUser: 'InstagramUser',
  InstagramUserMedia: 'InstagramUserMedia',
  InstagramUserStory: 'InstagramUserStory',
  InstagramUserReel: 'InstagramUserReel',
  InstagramAudience: 'InstagramAudience',
  TikTok: 'TikTok',
  TikTokUser: 'TikTokUser',
  TikTokUserVideo: 'TikTokUserVideo',
  Task: 'Task',
  TaskGroup: 'TaskGroup',
  Pretask: 'Pretask',
  PretaskDeniedReason: 'PretaskDeniedReason',
  SnsPretask: 'SnsPretask',
  SnsPretaskInspection: 'SnsPretaskInspection',
  Product: 'Product',
  Sku: 'Sku',
  CampaignSkuLink: 'CampaignSkuLink',
  ArrivalProduct: 'ArrivalProduct',
  ShippingProduct: 'ShippingProduct',
  Inspection: 'Inspection',
  Stock: 'Stock',
  Questionnaire: 'Questionnaire',
  QuestionnaireOption: 'QuestionnaireOption',
  ParticipantQuestionnaireAnswer: 'ParticipantQuestionnaireAnswer',
  SendbirdBrandUser: 'SendbirdBrandUser',
  SendbirdChannel: 'SendbirdChannel',
  SendbirdQueue: 'SendbirdQueue',
  SendbirdTemplateMessage: 'SendbirdTemplateMessage',
  MailgunQueue: 'MailgunQueue',
  InfluencerTempIdentification: 'InfluencerTempIdentification',
  InfluencerIdentification: 'InfluencerIdentification',
  InfluencerIdentificationFailedReason: 'InfluencerIdentificationFailedReason',
  Fan: 'Fan',
  FanList: 'FanList',
  FanListItem: 'FanListItem',
  UnregisteredFan: 'UnregisteredFan',
  MonthlyFanListScore: 'MonthlyFanListScore',
  InfluencerAmlRiskCheck: 'InfluencerAmlRiskCheck',
  MasterAmlRiskCheckProcessStatus: 'MasterAmlRiskCheckProcessStatus',
  MasterAmlRiskCheckResultStatus: 'MasterAmlRiskCheckResultStatus',
  MasterIdentificationProcessStatus: 'MasterIdentificationProcessStatus',
  MasterIdentificationResultStatus: 'MasterIdentificationResultStatus',
  MasterIdentificationFailedTarget: 'MasterIdentificationFailedTarget',
  MasterIdentificationFailedCode: 'MasterIdentificationFailedCode',
  MasterTaxWithholding: 'MasterTaxWithholding',
  MasterConsumptionTax: 'MasterConsumptionTax',
  MasterConsumptionTaxBurdenRate: 'MasterConsumptionTaxBurdenRate',
  MasterPaymentPlatform: 'MasterPaymentPlatform',
  MasterBank: 'MasterBank',
  BankAccount: 'BankAccount',
  SystemNotification: 'SystemNotification',
  MobileAppNewsFeedNotificationTemplate: 'MobileAppNewsFeedNotificationTemplate',
  MobileAppCampaignTasksReminderNotificationTemplate: 'MobileAppCampaignTasksReminderNotificationTemplate',
  TwitterReportQueue: 'TwitterReportQueue',
  UgcConsent: 'UgcConsent',
  InfluencerInvoiceInfo: 'InfluencerInvoiceInfo',
  ParticipantInvoice: 'ParticipantInvoice',
  AnnualPaymentReport: 'AnnualPaymentReport',
  CreativeImageTextInfo: 'CreativeImageTextInfo',
  OriginalUrl: 'OriginalUrl',
  countries: 'countries',
  states: 'states',
  cities: 'cities',
  UgcSdk: 'UgcSdk'
};

/**
 * Create the Client
 */
class PrismaClient {
  constructor() {
    throw new Error(
      `PrismaClient is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
    )
  }
}
exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
